import * as React from "react";
import constants from "constants/index";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import Tagline from "../../../texts/Tagline";
import Button from "../../../buttons/Button";
import Content from "../../../../components/layout/Content/Content";
import RichDescription from "../../../texts/RichDescription";

const Container = Styled.div`
  width: 100%;
  text-align: center;
  margin: 15rem 0 15rem 0;
  position: relative;
  padding: 0 14rem;
  
   ${Media.lessThan("1024px")`
      margin: 11rem 0 7rem 0;
  `}
  
  ${Media.lessThan("medium")`
      padding: 0 6rem;
  `}
  
  ${Media.lessThan("small")`
      padding: 0;
      margin: 9rem 0 4.5rem 0;
  `}
`;

const RichDescriptionExtended = Styled(RichDescription)`
    max-width: 70rem;
    margin: 0 auto 3rem auto;
    
   ${Media.lessThan("large")`
    max-width: 55rem;
  `}
`;

const NavButtonLink = Styled.a`
`;

const TaglineExtended = Styled(Tagline)`
  color: #7f7f7f;
`;

type SolutionsBannerProps = {
  tagline: string;
  headline: string;
  description: string;
};

export default ({ tagline, headline, description }: SolutionsBannerProps) => (
  <Content>
    <Container>
      <TaglineExtended as="h1">{tagline}</TaglineExtended>
      <Headline as="h2">{headline}</Headline>

      <RichDescriptionExtended content={description} />

      <NavButtonLink href={`https://${constants.APP_DOMAIN}/auth/sign-up`}>
        <Button solid>Sign up for free</Button>
      </NavButtonLink>
    </Container>
  </Content>
);
