import * as React from "react";
import constants from "constants/index";
import { graphql } from "gatsby";
import Styled from "styled-components/macro";
import Meta from "../../components/meta";
import Media from "@uizard-io/styled-media-query";
import Layout from "../../components/layout/Layout";
import SolutionsBanner from "../../components/pages/solutions/SolutionsBanner";
import HomeFeatured from "../../components/pages/home/HomeFeatured";
import HomeExamples from "../../components/pages/home/HomeExamples";
import Shoutout from "../../components/pages/signup/Shoutout";
import Content from "../../components/layout/Content/Content";
import HomeAutodesigner from "../../components/pages/home/HomeAutodesigner";
import SectionWithRightVideoMockup from "../../components/pages/solutions/SectionWithRightVideoMockup";
import SectionWithLeftImage from "../../components/pages/solutions/SectionWithLeftImage";
import SectionWithRightImage from "../../components/pages/solutions/SectionWithRightImage";
import HomeBannerStyleSection from "../../components/pages/home/HomeBannerStyleSection";

const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;

const Column = Styled.div`
  ${Media.greaterThan("700px")`
    min-width: 0;
  `}
`;

const ColumnIllustration = Styled(Column)`

  ${Media.greaterThan("large")`
    padding: 0 0 0 6rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `}
`;

const Home = ({ data }) => (
  <Layout>
    <Meta
      title="Uizard: Solutions and Use Cases | Uizard"
      description="Design for Different Solutions and Use Cases."
      url="/solutions/"
    />

    <SolutionsBanner
      tagline="Solutions"
      headline="Rapid, collaborative, AI-powered design"
      description="The future of design is here. Transform the way you approach any design project with the power of AI. Ideation, iteration, and collaboration will never be the same again."
    />

    <SectionWithRightVideoMockup
      tagline="for Product Owners"
      headline="Product & Project Managers"
      description="Bring your ideas to life and move your projects forward faster than ever before with the power of AI-assisted design."
      imageA={data.foundersImageBig.childImageSharp}
      imageB={data.foundersImageSmall.childImageSharp}
      imageAlt="Visual showcasing multiple screens being designed in Uizard"
      videoName="uizard-user-journey"
      link="/solutions/product-managers/"
    />

    <SectionWithLeftImage
      tagline="for Agencies"
      headline="Consultants & Agencies"
      description="Create stunning visuals in seconds. Spinning out app, web, and UI design deliverables for your clients has never been so easy."
      imageA={data.projectManagerImageBig.childImageSharp}
      imageB={data.projectManagerImageSmall.childImageSharp}
      imageAlt="Overview of multiple screens designed in Uizard, two for a mobile app and one for a website."
      link="/solutions/consultants/"
    />

    <SectionWithRightImage
      tagline="for Designers"
      headline="Designers & UX Pros"
      description="Test ideas, iterate on concepts, and collaborate with your team faster than ever. Uizard is here to change the way you and your team work on design projects."
      imageA={data.uxImageBig.childImageSharp}
      imageB={data.uxImageSmall.childImageSharp}
      imageAlt="Visual showcasing multiple screens being designed in Uizard"
      link="/solutions/ux-professionals/"
    />

    <SectionWithLeftImage
      tagline="for Entrepreneurs"
      headline="Startup Founders"
      description="Uizard for... founders and entrepreneurs. With the power of Uizard, you can portray your vision through quick and easy high quality design prototyping in minutes — whatever your product or service might be."
      imageA={data.foundersImageBig.childImageSharp}
      imageB={data.foundersImageSmall.childImageSharp}
      imageAlt="Overview of multiple screens designed in Uizard, two for a mobile app and one for a website."
      link="/solutions/startup-founders/"
    />

    <HomeFeatured />

    <HomeAutodesigner
        headline="Design made easy - powered by AI"
        description="The future of design is here. Uizard's easy-to-use, drag-and-drop editor empowers you to design and collaborate faster than ever before."
        description2="Kickstart your project from scratch or supercharge your productivity with AI, the choice is yours."
        imageA={data.autodesignerImageA.childImageSharp}
        imageB={data.autodesignerImageB.childImageSharp}
        imageAlt="turn text prompts into UI designs with AI"
        videoName="uizard-autodesigner-light"
        link="/ai-design/"
    />

    <HomeExamples />

    <ContentExtended>
        <Shoutout
          headline="Design. Simplified"
          description="App, web, and UI design made easy with the power of AI."
          cta="Sign up for free"
          imageA={data.shoutoutImageA.childImageSharp}
        />
    </ContentExtended>

  </Layout>
);

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    bannerImageBig: file(relativePath: { eq: "home/banner-image-a.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerImageA: file(
      relativePath: { eq: "ai/uizard-autodesigner-light-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerImageB: file(
      relativePath: { eq: "ai/uizard-autodesigner-light-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    projectManagerImageBig: file(
      relativePath: { eq: "solutions/UizardFor_ProjectManagers_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    projectManagerImageSmall: file(
      relativePath: { eq: "solutions/UizardFor_ProjectManagers_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersImageBig: file(
      relativePath: { eq: "solutions/UizardFor_Founders2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersImageSmall: file(
      relativePath: { eq: "solutions/UizardFor_Founders1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    uxImageBig: file(
      relativePath: { eq: "ux-professionals/UXPros_Cover_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1014, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    uxImageSmall: file(
      relativePath: { eq: "ux-professionals/UXPros_Cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 507, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    conversionImageA: file(
      relativePath: { eq: "solutions/UizardFor_Founders2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    conversionImageB: file(
      relativePath: { eq: "solutions/UizardFor_Founders1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
